<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Contacto</template>
    <template #body>
      <FormText
        label="Nombre"
        icon="fa-solid fa-user"
        v-model="form.name"
        disabled
      />
      <FormText
        label="Email"
        icon="fa-solid fa-envelope"
        v-model="form.email"
        disabled
      />
      <FormText
        label="Asunto"
        icon="fa-solid fa-envelope"
        v-model="form.subject"
        disabled
      />
      <FormText
        label="Mensaje"
        icon="fa-solid fa-envelope"
        v-model="form.message"
        disabled
        textarea
        textareaRows="30"
      />
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";

export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      youtube_token: null,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = this.item;

      this.setRead();
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    setRead() {
      if (!this.form.is_read) {
        this.$store.dispatch("updateContact", {
          id: this.form.contact_id,
          form: {
            is_read: true,
          },
        });
        this.$emit('saved')
      }
    },
  },
};
</script>
<style></style>
