<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Contactos</template>

        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="vTable.options"
          ref="vtable"
          @changed="getItems"
          @onShowItem="onShowItem"
          @onSetRead="onSetRead"
          @onSetUnread="onSetUnread"
          @onDeleteItem="deleteItem"
        />
      </Widget>
    </div>

    <ContactoModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import ContactoModal from "./ContactoModal.vue";

export default {
  components: {
    Widget,
    ContactoModal,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "name",
          mask: "Nombre",
          sortable: true,
        },
        {
          title: "email",
          mask: "Email",
          sortable: true,
        },
        {
          title: "subject",
          mask: "Asunto",
          sortable: true,
        },
        {
          title: "created_at",
          mask: "Recibido",
          dateFormat: "DD-MM-YYYY HH:mm",
          sortable: true,
        },
        {
          title: "is_read",
          mask: "Leído",
          sortable: true,
          callback: (attr) => (attr ? "SI" : "NO"),
        },
      ],
      actions: [
        {
          buttonClass: "btn-primary",
          callback: "onSetRead",
          tooltip: "Marca como leído",
          icon: "fa-solid fa-envelope-open",
        },
        {
          buttonClass: "btn-primary",
          callback: "onSetUnread",
          tooltip: "Marca como no leído",
          icon: "fa-solid fa-envelope",
        },
        {
          buttonClass: "btn-info",
          callback: "onShowItem",
          tooltip: "Mostrar Detalles",
          icon: "fa-solid fa-eye",
        },
        {
          buttonClass: "btn-danger",
          callback: "onDeleteItem",
          tooltip: "Borrar Contacto",
          icon: "fa-solid fa-trash",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.contacts;
    },
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    closeModal() {
      this.selItem = null;
      this.showModal = false;
    },
    getItems(params) {
      this.$store.dispatch("getContacts", params);
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    deleteItem(item) {
      this.$alert
        .fireConfirm("Borrar Contacto", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("deleteContact", item.contact_id).then(() => {
           this.onSaveItem();
        });
        });
    },
    onSetRead(item) {
      this.$store.dispatch("updateContact", {
        id: item.contact_id,
        form: {
          is_read: true,
        },
      });
    },
    onSetUnread(item) {
      this.$store.dispatch("updateContact", {
        id: item.contact_id,
        form: {
          is_read: false,
        },
      });
    },
  },
};
</script>
